<template>
  <div>
    <div class="footerBar">
      <div class="rightBtn">
        <el-button type="info" size="medium" plain @click="cancalFun"
          >取消</el-button
        >

        <el-button
          type="primary"
          size="medium"
          :loading="loading"
          @click="submitFun"
          >确认提交</el-button
        >
      </div>
    </div>
    <subClass :isShow="classflag" @onCancal="onCancalFun"></subClass>
    <div class="contents">
      <div class="mainbox">
        <el-alert
          title="温馨提示：修改商品库信息不会同步和影响线上已开团的商品的信息详情。"
          type="warning"
          :closable="false"
          show-icon
        >
        </el-alert>

        <div class="formTable">
          <el-form
            :model="goodArr"
            :rules="rules"
            label-position="right"
            label-width="150px"
            size="mini"
            id="goodsForm"
          >
            <el-form-item label="商品名称：" prop="name">
              <el-input
                v-model="goodArr.name"
                type="textarea"
                :rows="2"
                placeholder="请输入商品名称"
              ></el-input>
            </el-form-item>

          <el-form-item label="商品简称：" class="classol w85" v-if="goodsSetting.enableShortName">
            <el-input v-model="goodArr.shortName" placeholder="请输入商品简称"></el-input>
          </el-form-item>

            <el-form-item label="商品类目：" prop="globalCategoryId">
              <el-select
                v-model="goodArr.globalCategoryId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in categoryArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="选择商品供应商："
              v-if="role == 'retailer'"
              required
            >
              <el-select
                v-model="supplierName"
                placeholder="请选择供应商"
                clearable
                @change="changeSupplier"
                filterable
                :filter-method="supplierFilter"
              >
                <el-option
                  v-for="item in supplierOrgs"
                  :key="item.targetOrgId"
                  :label="item.name"
                  :value="item.targetOrgId"
                  :disabled="item.cooperation==0"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="自定义分类："
              prop="categoryId"
              v-if="goodsSetting.enableGoodsCategory"
            >
              <el-select v-model="goodArr.categoryId" placeholder="请选择自定义分类" clearable @change="changeCategory">
                <el-option
                  v-for="item in classArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="classInfo"
                >如暂无分类选择，<el-button
                  type="text"
                  size="small"
                  @click="addClass"
                  >添加分类</el-button
                ></span
              >
            </el-form-item>

          <el-form-item label="商品描述：" class="classol w85" v-if="goodsSetting.enableDescription">
            <el-input v-model="goodArr.description" type="textarea" :rows="4" placeholder="请输入商品描述"></el-input>
          </el-form-item>

          <el-form-item label="产品卖点：" class="classol w85" v-if="goodsSetting.enableRecommend">
            <el-input v-model="goodArr.recommend" type="textarea" :rows="4" placeholder="请输入产品卖点"></el-input>
          </el-form-item>

          <el-form-item label="商品主图：" class="classol">
              <div class="groupMa">
                <el-upload
                  class="avatar-uploaderss"
                  style="float: left; margin-right: 10px"
                  action="string"
                  multiple
                  :before-upload="beforeAvatarUpload"
                  accept="image/png, image/jpeg, image/jpg"
                  :http-request="UploadImageFun" 
                  :show-file-list="false"
                  :limit="10"
                >
                  <i class="el-icon-picture-outline avatar-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">只能上传jpg,png,jpeg文件，且不超过1M，支持多张，可拖拽调整顺序22</div>
                </el-upload>
                <draggable v-model="goodArr.images" @update="datadragEnd">
                  <transition-group type="transition">
                    <div v-for="(item, index) in goodArr.images" :key="item">
                      <ol>
                        <el-image
                          class="avatarSize"
                          :src="item"
                          :preview-src-list="goodArr.images"
                        >
                        </el-image>
                        <div class="delfun" @click="handleDel(index)">×</div>
                      </ol>
                    </div>
                  </transition-group>
                </draggable>
              </div>
          </el-form-item>

          <el-form-item label="商品视频：">
            <el-upload
              class="avatar-uploaderss"
              action="string"
              :http-request="UploadVideo"
              :before-upload="beforeVideoUpload"
              :show-file-list="false"
              :limit="1"
            >
              <i class="el-icon-video-camera avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">
                只能上传Mp4文件，且不超过50M
              </div>
            </el-upload>
            <div class="avatarVideo" v-if="goodArr.video">
              <video
                controls="controls"
                :src="goodArr.video"
                class="avatarSize"
                style="width: 100%; height: 100%; object-fit: fill"
              ></video>
            </div>
          </el-form-item>


          <el-form-item label="详情图册：" class="classol" v-if="goodsSetting.enableDetail">
              <div class="groupMa">
                <el-upload
                  accept="image/png, image/jpeg, image/jpg"
                  class="avatar-uploaderss"
                  style="float: left; margin-right: 10px"
                  action="string"
                  multiple
                  :before-upload="beforeAvatarUpload"
                  :http-request="uploadDetailImage"
                  :show-file-list="false"
                  :limit="10"
                >
                  <i class="el-icon-picture-outline avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">只能上传jpg,png,jpeg文件，且不超过1M，支持多张，可拖拽调整顺序</div>
                </el-upload>
                <draggable v-model="goodArr.detail" @update="detailDragEnd">
                  <transition-group type="transition">
                    <div v-for="(item, index) in goodArr.detail" :key="item">
                      <ol>
                        <el-image
                          class="avatarSize"
                          :src="item"
                          :preview-src-list="goodArr.detail"
                        >
                        </el-image>
                        <div class="delfun" @click="handleDetailDel(index)">×</div>
                      </ol>
                    </div>
                  </transition-group>
                </draggable>
              </div>
          </el-form-item>

          <el-form-item label="网盘资料：" class="classol w85" v-if="goodsSetting.enablePan">
            <el-input v-model="wangpan" style="float:left;width:500px;" type="textarea" :rows="2" placeholder="请输入网盘地址和提取码"></el-input>
            <el-button style="float:left;margin-left:20px;margin-top:16px;" type="primary" @click="addPan()">添加</el-button>
            <div style="clear:both;"></div>
            <li v-for="(item,index) in goodArr.pan" :key="index">
            <span class="prefix">网盘地址：</span> <span>{{item.url}}</span>  <span class="prefix">密码：</span><span>{{item.password}}</span>
            </li>
          </el-form-item>

          <el-form-item label="物流类型：" class="classol w85">
                <el-radio-group v-model="goodArr.deliveryType" @change="() => this.$refs.selectDeliveryTemplate.clear()">
                  <el-radio :label="1">快递发货</el-radio>
                  <el-radio :label="0">无需物流</el-radio>
                </el-radio-group>
          </el-form-item>

            <k-select ref="selectDeliveryTemplate"
                v-show="goodArr.deliveryType==1"
                v-model="goodArr.deliveryTemplate"
                prop="deliveryTemplate"
                label="运费模板："
                placeholder="请选择运费模板"
                optionKey="id"
                optionValue="id"
                optionLabel="name"
                clearable
                :filterMethod="getDeliveryTemplateList"
                :disableWhen="(opt) => opt.status == 2"
            >
            </k-select>

            <k-select ref="kSelect"
                v-model="goodArr.purchaserId"
                prop="purchaserId"
                label="采购负责人："
                placeholder="请选择采购负责人"
                optionKey="id"
                optionValue="id"
                optionLabel="nickName"
                clearable
                :filterMethod="getPurchaserList"
                :disableWhen="(opt) => opt.status == 2"
            ></k-select>
  

            <el-form-item
              label="品牌："
              class="classol w85"
              v-if="goodsSetting.enableGoodsBrand"
            >
              <el-input v-model="goodArr.brand" placeholder="请输入商品品牌名称"></el-input>
            </el-form-item>

            <el-form-item
              label="产地："
              class="classol w85"
              v-if="goodsSetting.enableGoodsPlace"
            >
              <el-input v-model="goodArr.place" placeholder="请输入商品产地"></el-input>
            </el-form-item>

          <el-form-item label="售后政策：" class="classol w85" v-if="goodsSetting.enableAfterSale">
            <el-input v-model="goodArr.afterSale" type="textarea" :rows="4" placeholder="请输入商品售后政策"></el-input>
          </el-form-item>


            <el-form-item
              label="商品参数："
              class="classol property"
              v-if="goodsSetting.enableGoodsProperties"
            >
              <el-input
                style="width: 200px; margin-bottom: 10px; float: left"
                v-model="extendKey"
              ></el-input>
              &nbsp;&nbsp;:&nbsp;&nbsp;
              <el-input
                style="width: 300px; margin-bottom: 10px"
                v-model="extendValue"
              ></el-input>
              <div class="additem" @click="addExtend">+</div>
              <div
                v-for="(item, index) in goodArr.extend"
                :key="index"
                style="margin-bottom: 10px"
              >
                <span class="extendKey">{{ item.key }}</span
                >&nbsp;:&nbsp;<span class="extendValue">{{ item.value }}</span>
                <div class="delitem" @click="delExtend(index)">×</div>
              </div>
            </el-form-item>

            <el-form-item
              label="物流属性："
              class="classol property"
              v-if="goodsSetting.enableDeliveryExtend"
            >              
              <el-select v-model="deliveryExtendKey"
                style="width: 200px; margin-bottom: 10px; float: left">
                <el-option value="发货地" label="发货地"></el-option>
                <el-option value="发货时效" label="发货时效"></el-option>
                <el-option value="发货快递" label="发货快递"></el-option>
                <el-option value="不发货地区" label="不发货地区"></el-option>
              </el-select>
              &nbsp;&nbsp;:&nbsp;&nbsp;
              <el-input
                style="width: 300px; margin-bottom: 10px"
                v-model="deliveryExtendValue"
              ></el-input>
              <div class="additem" @click="addDeliveryExtend()">+</div>
              <div
                v-for="(item, index) in goodArr.deliveryExtend"
                :key="index"
                style="margin-bottom: 10px"
              >
                <span class="extendKey">{{ item.key }}</span
                >&nbsp;:&nbsp;<span class="extendValue">{{ item.value }}</span>
                <div class="delitem" @click="delDeliveryExtend(index)">×</div>
              </div>
            </el-form-item>

            <el-form-item :label="role == 'supplier' ? '供货价含税：' : '采购价含税：'" class="classol w85">
                  <el-radio-group v-model="goodArr.tax">
                    <el-radio :label="0">不含税</el-radio>
                    <el-radio :label="1">含税</el-radio>
                  </el-radio-group>
            </el-form-item>

            <div v-if="guigeType == 1">
              <el-form-item label="商品规格：">
                <el-input
                  v-model="single.skuName"
                  placeholder="请输入尺寸，颜色，克重等"
                  style="width: 80%; margin-right: 10px"
                ></el-input>
                <el-button @click="addSku" icon="el-icon-edit" v-if="!editing"
                  >添加多规格</el-button
                >
              </el-form-item>
              <el-form-item label="规格编码：" class="classol w85">
                <el-input
                  v-model="single.skuCode"
                  placeholder="系统自动生成"
                ></el-input>
              </el-form-item>

              <el-form-item label="划线价(元)：" class="classol w85">
                <el-input-number
                  v-model="single.linePrice"
                  :controls="false"
                  :precision="2"
                  placeholder="请输入划线价"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                required
                :label="role == 'retailer' ? '销售价(元)：' : '供货价(元)：'"
                class="classol w85"
              >
                <el-input-number
                  v-model="single.salePrice"
                  :controls="false"
                  :precision="2"
                  placeholder="请输入价格"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                required
                :label="role == 'retailer' ? '采购价(元)：' : '成本价(元)：'"
                class="classol w85"
              >
                <el-input-number
                  v-model="single.costPrice"
                  :controls="false"
                  :precision="2"
                  placeholder="请输入价格"
                ></el-input-number>
              </el-form-item>

              <el-form-item
                label="国标条码："
                class="classol w85"
                v-if="single.showMore == true"
              >
                <el-input
                  v-model="single.barCode"
                  placeholder="请输入国标条形码"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="商品重量(千克)："
                class="classol w85"
                v-if="single.showMore == true"
              >
                <el-input-number
                  v-model="single.weight"
                  placeholder="请输入商品重量"
                  :min="0.001"
                  :precision="3"
                ></el-input-number>
              </el-form-item>

          <!-- <el-form-item label="商品类型：" class="classol w85" v-if="goodArr.showMore==true && goodsSetting.enableGoodsVirtual">
              <el-radio-group v-model="goodArr.cate" >
                <el-radio :label="0">实物商品</el-radio>
                <el-radio :label="1">虚拟商品</el-radio>
              </el-radio-group>
          </el-form-item> -->

          <!-- 
          <el-form-item label="发货方式：" class="classol" style="width:200px;" v-if="goodArr.showMore==true && goodsSetting.enableGoodsVirtual">
              <el-radio-group v-model="goodArr.skuDeliveryType" style="line-height:25px;">
                <el-radio :label="0">手动发货</el-radio>
                <el-radio :label="1">固定内容自动发货</el-radio>
                <el-radio :label="2">动态内容自动发货</el-radio>
              </el-radio-group>
          </el-form-item>

          <el-form-item label="固定发货内容：" class="classol w85" v-if="goodArr.showMore==true && goodArr.skuDeliveryType==1">
            <el-input type="textarea"
              rows="5"
             v-model="goodArr.deliveryStaticContent.content" placeholder="请输入商品发货内容"></el-input>
          </el-form-item>

          <el-form-item label="使用多级分销价格：" class="classol" v-if="role=='supplier' && goodArr.showMore==true">
              <el-radio-group v-model="goodArr.multiSalePrice">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
          </el-form-item> -->

              <!-- <el-form-item
                label="备注："
                class="classol w85"
                v-if="goodArr.showMore == true"
              >
                <el-input
                  v-model="goodArr.remark"
                  placeholder="请输入商品备注"
                ></el-input>
              </el-form-item> -->

              <el-form-item class="classol">
                <el-button
                  v-if="single.showMore == false"
                  @click="single.showMore = true"
                  >显示更多设置<i class="el-icon-caret-bottom"></i
                ></el-button>
                <el-button
                  v-if="single.showMore == true"
                  @click="single.showMore = false"
                  >收起更多设置<i class="el-icon-caret-top"></i
                ></el-button>
              </el-form-item>
            </div>
            <div v-else>
              <div class="subbox">
                <div class="duoguige">
                  <span
                    ><el-button
                      v-if="!editing"
                      @click="closeSku"
                      icon="el-icon-error"
                      >退出多规格</el-button
                    ></span
                  >
                  多规格设置（共{{ tableData.length }}个规格)
                </div>
                <div class="setGuige">
                  <div class="guigeBox" v-for="(item, j) in skuArrs" :key="j">
                    <div class="guigeLi">
                      <span>规格{{ j + 1 }}标题：</span>
                      <div class="guiboxzi">
                        <el-input
                          v-model="item.name"
                          @blur="showTable"
                          :placeholder="'请输入标题，如 ' + guigeTxt[j]"
                        ></el-input>
                        <div class="guigeDel" @click="delhezi(j)">×</div>
                      </div>
                      <el-tooltip
                        content="可按照sku批量添加sku图片"
                        placement="top"
                      >
                        <el-checkbox
                          v-if="j == 0"
                          v-model="checked"
                          @change="changeImg"
                          >添加图片</el-checkbox
                        >
                      </el-tooltip>
                    </div>
                    <div class="guigeLi">
                      <span>具体规格：</span>
                      <ol v-for="(items, i) in item.desc" :key="i">
                        <el-input
                          placeholder="请输入"
                          v-model="items.value"
                          @blur="showTd"
                          style="width: 120px"
                        ></el-input>
                        <div class="guigeDel" @click="delfun(j, i)">×</div>
                        <el-upload
                          v-if="checked && j == 0"
                          class="avatar-uploaderss"
                          action="string"
                          :http-request="UploadSizes"
                          :show-file-list="false"
                          :limit="1"
                          :data="{ keys: i }"
                          :before-upload="beforeAvatarUpload"
                        >
                          <img
                            v-if="items.image"
                            :src="items.image"
                            class="avatarSize"
                          />
                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                          ></i>
                        </el-upload>
                      </ol>
                      <div class="addkuang">
                        <el-button @click="addkuang(j)" icon="el-icon-plus"
                          >添加规格</el-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="guigeFen" v-if="skuArrs.length < 4">
                    <el-button
                      @click="addFenlei(skuArrs.length)"
                      icon="el-icon-plus"
                      >添加规格分类</el-button
                    >
                  </div>
                </div>
                <div class="guiTable" v-if="rArrs.length > 0">
                  <el-table
                    :data="tableData"
                    border
                    :header-cell-style="{
                      background: '#f7f7f7',
                      color: '#666',
                    }"
                    stripe
                    style="width: 100%"
                  >
                    <el-table-column
                      type="index"
                      label="序号"
                      header-align="center"
                      align="center"
                      width="50"
                    >
                    </el-table-column>
                    <template v-for="(item, index) in rArrs">
                      <el-table-column
                        v-if="
                          item.role == undefined ||
                          item.role == '' ||
                          item.role == role
                        "
                        :prop="item.prop"
                        header-align="center"
                        align="center"
                        :width="item.width"
                        :label="item.label"
                        :key="index"
                      >
                        <template slot-scope="scope">
                          <span v-if="item.prop === 'skuCode'">
                            <el-input
                              placeholder="系统自动生成"
                              v-model="scope.row[item.prop]"
                            ></el-input>
                          </span>
                          <span v-else-if="item.prop === 'barCode'">
                            <el-input
                              placeholder="输入国标码"
                              v-model="scope.row[item.prop]"
                            ></el-input>
                          </span>
                          <span
                            v-else-if="
                              item.prop === 'costPrice' ||
                              item.prop === 'salePrice' ||
                              item.prop === 'linePrice'
                            "
                          >
                            <el-input
                              placeholder="请输入"
                              v-model="scope.row[item.prop]"
                              @blur="
                                tableMoney(
                                  scope.row[item.prop],
                                  item.prop,
                                  scope.$index
                                )
                              "
                            ></el-input>
                          </span>
                          <span v-else-if="item.prop === 'weight'">
                            <el-input-number
                              style="width: 85px"
                              :precision="3"
                              placeholder="输入重量"
                              :controls="false"
                              v-model="scope.row[item.prop]"
                            ></el-input-number>
                          </span>
                          <span
                            v-else-if="
                              item.prop == 'image' && scope.row[item.prop]
                            "
                          >
                            <img
                              :src="scope.row[item.prop]"
                              class="sizeimgss"
                            />
                          </span>
                          <!-- <span v-else-if="item.prop === 'action'">
                            <el-button
                              type="text"
                              size="small"
                              @click="skuMoreConfig(scope.row, scope.$index)"
                              >更多设置</el-button
                            >
                          </span> -->
                          <span v-else>
                            {{ scope.row[item.prop] }}
                          </span>
                        </template>
                      </el-table-column>
                    </template>
                  </el-table>
                  <div class="piliangBtn" v-if="tableData.length > 0">
                  <el-button type="primary" size="small" @click="piliangSetPrice(1)">批量设置划线价</el-button>
                  <el-button type="primary" size="small" @click="piliangSetPrice(2)">批量设置{{role=='supplier'?'供货价':'销售价'}}</el-button>
                  <el-button type="primary" size="small" @click="piliangSetPrice(3)">批量设置{{role=='supplier'?'成本价':'采购价'}}</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <!-- /* 批量设置价格 */ -->
    <el-dialog
      :title="priceTitle"
      :visible.sync="bumendialogVisible"
      center
      width="30%"
      :before-close="piliangClose">
      <span><el-input v-model="piliangPrice" maxlength="30" placeholder="请输入价格"></el-input></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bumendialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="querenSetPrice">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="'更多设置(序号：' + (editRowIndex + 1) + ')'"
      :visible.sync="skuMoreConfigFlag"
      width="600px"
    >
      <el-form :model="editRow" label-position="right" label-width="150px">
          <!-- <el-form-item label="商品类型：" class="classol w85" v-if="goodsSetting.enableGoodsVirtual">
              <el-radio-group v-model="editRow.cate" >
                <el-radio :label="0">实物商品</el-radio>
                <el-radio :label="1">虚拟商品</el-radio>
              </el-radio-group>
          </el-form-item> -->

        <!-- <el-form-item label="使用多级分销价格：" class="classol" v-if="role=='supplier'">
              <el-radio-group v-model="editRow.multiSalePrice">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
          </el-form-item> 

          <el-form-item label="发货方式：" class="classol" style="width:200px;"  v-show="goodsSetting.enableGoodsVirtual && editRow.cate==1">
              <el-radio-group v-model="editRow.deliveryType" style="line-height:25px;">
                <el-radio :label="0">手动发货</el-radio>
                <el-radio :label="1">固定内容自动发货</el-radio>
                <el-radio :label="2">动态内容自动发货</el-radio>
              </el-radio-group>
          </el-form-item>

          <el-form-item label="固定发货内容：" class="classol w85" v-show="goodsSetting.enableGoodsVirtual && editRow.deliveryType==1">
            <el-input type="textarea"
              rows="5"
              v-model="deliveryStaticContentText" placeholder="请输入商品发货内容"></el-input>
          </el-form-item>
          -->

        <!-- <el-form-item label="商品参数：" class="classol w85">
          <el-input
            style="width: 100px; margin-bottom: 10px; float: left"
            v-model="skuExtendKey"
          ></el-input>
          ：
          <el-input
            style="width: 150px; margin-bottom: 10px"
            v-model="skuExtendValue"
          ></el-input>
          <div class="additem" @click="addSkuExtend">+</div>
          <div
            v-for="(item, index) in editRow.extend"
            :key="index"
            style="margin-bottom: 10px"
          >
            <span class="extendKey">{{ item.key }}</span
            >：<span class="extendValue">{{ item.value }}</span>
            <div class="delitem" @click="delSkuExtend(index)">×</div>
          </div>
        </el-form-item> -->

        <el-form-item label="备注：" class="classol w85">
          <el-input
            v-model="editRow.remark"
            placeholder="请输入商品备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="skuMoreConfigFlag = false">取消</el-button>
        <el-button type="primary" @click="setSkuMoreConfig">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import subClass from "@/components/getClass";
import { uploadFile, globalCategory } from "@/api/common";
import { getCategory, addGood, getGoodDetail, editGood, listDeliveryTemplate, parsePan } from "@/api/good";
import { listSupplier } from "@/api/supplier";
import { getSupplierSetting, getData } from "@/api/system";
import { getUserList } from "@/api/user";
import  kSelect  from "@/components/kselect";

export default {
  name: "ktyGoodDetail",
  components: { draggable, subClass , kSelect},
  data() {
    return {
      role: window.sessionStorage.getItem("role"),
      wangpan: '',
      classArr: [],
      single: {
            showMore: false,
            skuId: null,
            linePrice: null,
            salePrice: null,
            costPrice: null,
            skuCode: null,
            skuName: null,
            barCode: null,
            weight: null,
      },
      guigeTxt: ["颜色", "尺寸", "克重", "型号"],
      goodArr: {
        id: "",
        skuId: "",
        name: "",
        specs: "",
        categoryId: "",
        globalCategoryId: "",
        video: "",
        salePrice: "",
        costPrice: "",
        code: "",
        skuCode: "",
        skus: "",
        images: [],
        extend: [],
        deliveryExtend: [],
        cate: 0,
        deliveryType: 1,
        brand: "",
        place: "",
        showMore: false,
        barCode: "",
        weight: "",
        remark: "",
        multiSalePrice: 0,
        skuDeliveryType: 0,
        deliveryStaticContent: {
          content: "",
        },
        purchaserId: null,
        purchaserName: "",
        deliveryTemplate: null,
        deliveryTemplateName: "",
        saleStatus: 1,
        detail: [],
        pan: null,
        afterSale: '',
        tax: 0,
        description: "",
        recommend: "",
        shortName: "",
      },
      checked: false,
      classflag: false,
      loading: false,
      categoryArr: [],
      groupCode: [],
      skuArrs: [],
      rArrs: [],
      tuanBegin: null,
      guigeType: 1,
      guige: {
        code: "spec0",
        name: "",
        desc: [
          {
            image: "",
            value: "",
          },
        ],
      },
      resultArrs: [
        {
          prop: "skuCode",
          label: "规格编码",
        },
        {
          prop: "barCode",
          label: "国标条码",
        },
        {
          prop: "linePrice",
          label: "划线价(元)",
          width: 100,
        },
        {
          prop: "salePrice",
          label:
            window.sessionStorage.getItem("role") == "supplier"
              ? "供货价(元)"
              : "销售价(元)",
          width: 100,
        },
        {
          prop: "costPrice",
          label:
            window.sessionStorage.getItem("role") == "supplier"
              ? "成本价(元)"
              : "采购价(元)",
          width: 100,
        },
        {
          prop: "weight",
          label: "重量(千克)",
          width: 110,
        },
        // {
        //   prop: 'image',
        //   label: '图片',
        // },
        // {
        //   prop: "action",
        //   label: "操作",
        //   width: 80,
        // },
      ],
      tableData: [],
      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        globalCategoryId: [
          { required: true, message: "请选择商品类目", trigger: "change" },
        ],
      },
      editing: false,
      historySkus: [],
      extendKey: "",
      extendValue: "",
      deliveryExtendKey: "",
      deliveryExtendValue: "",
      skuMoreConfigFlag: false,
      editRowIndex: null,
      editRow: {
        cate: 0,
        barCode: "",
        deliveryType: 0,
        multiSalePrice: 0,
        remark: "",
        extend: [],
        weight: "",
        deliveryStaticContent: {
          content: "",
        },
      },
      deliveryStaticContentText: "",
      skuExtendKey: "",
      skuExtendValue: "",
      supplierOrgs: [],
      supplierId: "",
      supplierName: "",
      goodsSetting: {},
      getPurchaserList: getUserList,
      getDeliveryTemplateList: (val) => listDeliveryTemplate({...val, role: this.role}),
      bumendialogVisible: false,
      piliangPrice: 0,
      priceType: 1,
      priceTitle: '批量设置'
    };
  },
  created: function () {
    this.skuArrs.push(this.guige);
    if (this.role == "supplier") {
      getSupplierSetting({}).then((res) => {
        if (res.code === 200) {
          this.goodsSetting = res.data.supplierSetting.goodsSetting;
        } else {
          this.$message.error(res.message);
        }
      });
    } else {
      getData({}).then((res) => {
        if (res.code === 200) {
          this.goodsSetting = res.data.retailerSetting.goodsSetting;
        } else {
          this.$message.error(res.message);
        }
      });
    }
  },
  methods: {   
    addPan() {
      const param = {
        content: this.wangpan, 
        pans: this.goodArr.pan,
      }
      const that = this
      parsePan(param).then(res => {
        if(res.code === 200) {
          that.wangpan = ''
          that.goodArr.pan = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    changeSupplier(supplier) {
      this.supplierId = supplier;
    },
    changeCategory(e){
      console.log(e)
      this.goodArr.categoryId = e
    },
    supplierFilter(val) {
      listSupplier({ name: val }).then((res) => {
        if (res.code === 200) {
          this.supplierOrgs = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    setSkuMoreConfig() {
      console.log(this.tableData, this.editRow);
      this.editRow.deliveryStaticContent = {
        content: this.deliveryStaticContentText,
      };
      this.deliveryStaticContentText = "";
      this.tableData[this.editRowIndex] = this.editRow;
      this.skuMoreConfigFlag = false;
    },
    skuMoreConfig(row, index) {
      this.deliveryStaticContentText =
        row.deliveryStaticContent && row.deliveryStaticContent.content
          ? row.deliveryStaticContent.content
          : "";

      this.editRow = row;
      this.editRowIndex = index;
      this.skuMoreConfigFlag = true;
    },
    addSkuExtend() {
      if (this.skuExtendKey == "" || this.skuExtendValue == "") return;
      this.editRow.extend = this.editRow.extend || [];
      this.editRow.extend.push({
        key: this.skuExtendKey,
        value: this.skuExtendValue,
      });
      this.skuExtendKey = "";
      this.skuExtendValue = "";
    },
    delSkuExtend(index) {
      this.editRow.extend.splice(index, 1);
    },
    addExtend() {
      if (this.extendKey.trim() == "" || this.extendValue.trim() == "") return;
      this.goodArr.extend = this.goodArr.extend || [];
      this.goodArr.extend.push({
        key: this.extendKey.trim(),
        value: this.extendValue.trim(),
      });
      this.extendKey = "";
      this.extendValue = "";
    },
    delExtend(index) {
      this.goodArr.extend.splice(index, 1);
    },
    addDeliveryExtend() {
      if (this.deliveryExtendKey.trim() == "" || this.deliveryExtendValue.trim() == "") return;
      this.goodArr.deliveryExtend = this.goodArr.deliveryExtend || [];
      this.goodArr.deliveryExtend.push({
        key: this.deliveryExtendKey.trim(),
        value: this.deliveryExtendValue.trim(),
      });
      this.deliveryExtendKey = "";
      this.deliveryExtendValue = "";
    },
    delDeliveryExtend(index) {
      this.goodArr.deliveryExtend.splice(index, 1);
    },
    getCategoryList() {
      var that = this;
      const param = {
        role: window.sessionStorage.getItem("role"),
      };
      getCategory(param).then((res) => {
        console.log(res);
        if (res.code === 200) {
          that.classArr = res.data;
          console.log(that.classArr);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getCategoryAll() {
      var that = this;
      globalCategory().then((res) => {
        console.log(res);
        if (res.code === 200) {
          that.categoryArr = res.data;
          console.log(that.classArr);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    datadragEnd(evt) {
      var that = this;
      console.log("拖动前的索引 :" + evt.oldIndex);
      console.log("拖动后的索引 :" + evt.newIndex);
      console.log(that.goodArr.images);
      // that.goodArr.images = that.goodArr.images
    },
    detailDragEnd(evt) {
      var that = this;
      console.log("拖动前的索引 :" + evt.oldIndex);
      console.log("拖动后的索引 :" + evt.newIndex);
      console.log(that.goodArr.detail);
      // that.goodArr.images = that.goodArr.images
    },
    getGoodDetails(ids) {
      const that = this;
      var objs = {};
      objs.id = ids;
      getGoodDetail(objs).then((res) => {
        if (res.code === 200) {
          that.supplierId = res.data.supplierOrgId;
          that.supplierName = res.data.supplierName;
          that.goodArr = res.data
          if(that.goodArr.multi) {
            that.checked = true
          }
          that.goodArr.date = [];
          that.goodArr.id = res.data.id;
          that.goodArr.code = res.data.code;
          that.goodArr.name = res.data.name;
          that.goodArr.categoryId = JSON.parse(JSON.stringify(res.data.category.id));
          that.goodArr.globalCategoryId = res.data.globalCategory.id;
          that.goodArr.video = res.data.video;
          that.goodArr.images = res.data.images;
          that.goodArr.cate = res.data.cate;
          that.goodArr.deliveryType = res.data.deliveryType;
          that.goodArr.extend = res.data.extend;
          that.goodArr.place = res.data.place;
          that.goodArr.brand = res.data.brand;
          that.goodArr.purchaserId = res.data.purchaserId
          that.goodArr.purchaserName = res.data.purchaserName
          that.goodArr.deliveryTemplate = res.data.deliveryTemplate
          that.goodArr.deliveryTemplateName = res.data.deliveryTemplateName
          that.goodArr.saleStatus = res.data.saleStatus
          if (res.data.multi.length > 0) {
            that.guigeType = 2;
            that.skuArrs = res.data.multi;
            that.tableData = res.data.skus;
            that.historySkus = JSON.parse(JSON.stringify(res.data.skus));

            for (var i = 0; i < that.tableData.length; i++) {
              that.tableData[i].tag = that.tableData[i].id;
              for (var j = 0; j < that.skuArrs.length; j++) {
                let specValue = that.tableData[i][that.skuArrs[j].code];
                for (var x = 0; x < that.skuArrs[j].desc.length; x++) {
                  const k1 = that.skuArrs[j].code + specValue;
                  const k2 =
                    that.skuArrs[j].code + that.skuArrs[j].desc[x].value;
                  if (k1 == k2) {
                    that.skuArrs[j].desc[x].tags =
                      that.skuArrs[j].desc[x].tags || [];
                    that.skuArrs[j].desc[x].tags.push(that.tableData[i].tag);
                  }
                }
              }
            }
            console.log("skuArrs", that.skuArrs);
            that.showTable();
          } else {            
            that.guigeType = 1;
            let single = {}
            single.showMore = false
            single.id = res.data.skus[0].id;
            single.linePrice = res.data.single.linePrice;
            single.salePrice = res.data.single.salePrice;
            single.costPrice = res.data.single.costPrice;
            single.skuCode = res.data.single.skuCode;
            single.skuName = res.data.single.skuName;
            single.barCode = res.data.single.barCode;
            single.weight = res.data.single.weight
            console.log("single spec", single)
            that.single = single
          }
          console.log("purchaserId", that.goodArr.purchaserId)
          // that.goodArr.minPrice = res.data.minPrice
          // that.goodArr.images = res.data.images
          // that.groupCode = res.data.barImages
          // that.goodsData = res.data.goods
          // that.goodArr.status = res.data.status
        } else {
          this.$message.error(res.message);
        }
      });
    },
    UploadImageFun(param) {
      var that = this;
      const formData = new FormData();
      console.log(param);
      formData.append("file", param.file);
      uploadFile(formData)
        .then((res) => {
          if (res.code === 200) {
            console.log("上传图片成功2");
            console.log(that.goodArr.images + '99099');
            // param.onSuccess()  // 上传成功的图片会显示绿色的对勾
            that.goodArr.images.push(res.data.url);
            
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          that.$message.error(response.message);
          param.onError();
        });
    },
    uploadDetailImage(param) {
      var that = this;
      const formData = new FormData();
      console.log(param);
      formData.append("file", param.file);
      uploadFile(formData)
        .then((res) => {
          if (res.code === 200) {
            console.log("上传图片成功000000");
            that.goodArr.detail.push(res.data.url);
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          that.$message.error(response.message);
          param.onError();
        });
    },
    handleDel(n) {
      var that = this;
      that.goodArr.images.splice(n, 1);
    },
    handleDetailDel(n) {
      var that = this;
      that.goodArr.detail.splice(n, 1);
    },
    toMoney(num, names) {
      num = num || "0";
      var that = this;
      var nums = parseFloat(num).toFixed(2);
      that.goodArr[names] = nums;
    },
    tableMoney(num, names, n) {
      var that = this;
      num = num || "0";
      console.log(n);
      var nums = parseFloat(num).toFixed(2);
      that.tableData[n][names] = nums;
    },
    UploadVideo(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadFile(formData)
        .then((res) => {
          if (res.code === 200) {
            console.log("视频上传成功");
            // param.onSuccess()  // 上传成功的图片会显示绿色的对勾
            that.goodArr.video = res.data.url;
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("视频上传失败");
          that.$message.error(response.message);
          param.onError();
        });
    },
    UploadSource(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadFile(formData)
        .then((res) => {
          console.log("上传图片成功0909099111");
          // param.onSuccess()  // 上传成功的图片会显示绿色的对勾
          that.groupCode[param.data.n].resourceCode = res.data.url;
          console.log(that.goodArr.barCodeUrls);
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    UploadSizes(param) {
      var that = this;
      console.log(param);
      console.log("当前第几个" + param.data.keys);
      const formData = new FormData();
      formData.append("file", param.file);
      uploadFile(formData)
        .then((res) => {
          console.log("上传图片成功666");
          console.log(that.skuArrs);
          // param.onSuccess()  // 上传成功的图片会显示绿色的对勾
          that.skuArrs[0].desc[param.data.keys].image = res.data.url;
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    addClass() {
      this.classflag = true;
    },
    onCancalFun(vv) {
      this.classflag = vv;
      this.getCategoryList();
    },
    addkuang(n, i) {
      console.log(n, i);
      console.log(this.skuArrs);
      var objs = {
        image: "",
        value: "",
      };
      this.skuArrs[n].desc.push(objs);
    },
    showTd() {
      var that = this;
      var tdValue = that.skuArrs;
      var tdArrss = [];
      var values = [];
      tdValue.map((item) => {
        var arrays = [];
        item.desc.map((it) => {
          if (
            it.value.length > 0 &&
            values.indexOf(item.code + it.value) == -1
          ) {
            var daiInput = {
              skuCode: "",
              costPrice: "",
              salePrice: "",
              linePrice: "",
              weight: "",
              image: "",
            };
            values.push(item.code + it.value);
            daiInput[item.code] = it.value;
            daiInput.image = it.image;
            arrays.push(daiInput);
          }
        });
        tdArrss.push(arrays);
      });
      console.log(tdArrss);
      console.log("分割线————————————————————");
      console.log(that.doExchange(tdArrss));
      const n2e = (v) => {
        return v == null || v == undefined ? "" : v;
      };
      var tempTableData = that.doExchange(tdArrss);
      for (var i = 0; i < tempTableData.length; i++) {
        const row1 = tempTableData[i];
        for (var j = 0; j < that.tableData.length; j++) {
          const row2 = that.tableData[j];
          const k1 =
            n2e(row1.spec0) +
            n2e(row1.spec1) +
            n2e(row1.spec2) +
            n2e(row1.spec3);
          const k2 =
            n2e(row2.spec0) +
            n2e(row2.spec1) +
            n2e(row2.spec2) +
            n2e(row2.spec3);
          if (k1 == k2) {
            tempTableData[i] = row2;
          }
        }
      }
      that.tableData = tempTableData;
    },
    doExchange(arr) {
      var len = arr.length;
      // 当数组大于等于2个的时候
      if (len >= 2) {
        // 第一个数组的长度
        var len1 = arr[0].length;
        // 第二个数组的长度
        var len2 = arr[1].length;
        // 2个数组产生的组合数
        var lenBoth = len1 * len2;
        //  申明一个新数组,做数据暂存
        var items = new Array(lenBoth);
        // 申明新数组的索引
        var index = 0;
        // 2层嵌套循环,将组合放到新数组中
        console.log(arr[0]);
        console.log("__________分割线————————————————————");
        console.log(arr[1]);
        for (var i = 0; i < len1; i++) {
          for (var j = 0; j < len2; j++) {
            console.log(arr[0][i], arr[1][j]);
            console.log("duidui&&&");
            let newObj = { ...arr[1][j], ...arr[0][i] };
            items[index] = newObj;
            index++;
          }
        }
        console.log(items);
        // 将新组合的数组并到原数组中
        var newArr = new Array(len - 1);
        for (var a = 2; a < arr.length; a++) {
          newArr[a - 1] = arr[a];
        }
        newArr[0] = items;
        // 执行回调
        return this.doExchange(newArr);
      } else {
        return arr[0];
      }
    },
    showTable() {
      var that = this;
      var tdValue = that.skuArrs;
      console.log(tdValue.length);
      var tdArr = [];
      tdValue.map((item) => {
        var objNew = {};
        objNew.prop = item.code;
        objNew.label = item.name;
        tdArr.push(objNew);
      });
      that.rArrs = tdArr.concat(this.resultArrs);
      console.log(that.rArrs);
    },
    addFenlei(len) {
      var objss = {
        code: "spec" + len,
        name: "",
        desc: [
          {
            image: "",
            value: "",
          },
        ],
      };
      this.skuArrs.push(objss);
      this.showTable();
    },
    addSku() {
      this.guigeType = 2;
    },
    closeSku() {
      this.guigeType = 1;
    },
    changeImg(e) {
      console.log(e);
      this.checked = e;
    },
    delhezi(n) {
      console.log(n);
      for (var i = this.skuArrs[n].desc.length - 1; i >= 0; i--) {
        this.delfun(n, i);
      }
      this.skuArrs.splice(n, 1);
      this.showTable();
    },
    delfun(n, a) {
      console.log(n, a, this.tableData);
      const tags = this.skuArrs[n].desc[a].tags || [];
      var removeIds = [];
      for (var i = 0; i < tags.length; i++) {
        for (var j = 0; j < this.tableData.length; j++) {
          if (tags[i] == this.tableData[j].tag) {
            this.tableData[j].deleted = 1;
            removeIds.push(j);
          }
        }
      }
      this.skuArrs[n].desc.splice(a, 1);
      for (var i = removeIds.length - 1; i >= 0; i--) {
        this.tableData.splice(removeIds[i], 1);
      }
      this.showTable();
    },
    cancalFun() {
      this.$router.go(-1);
    },
    submitFun() {
      console.log(this.skuArrs);
      console.log(this.tableData);
      var that = this;
      var params = {
        ...that.goodArr,
        role: that.role,
      };
      if (!that.goodArr.name) {
        that.$message.error("请输入商品名称");
        return false;
      }
      // if(!that.goodArr.categoryId) { that.$message.error('请选择商品分类'); return false }
      //  if(that.guigeType ==2){
      //     for(var ii=0; ii<that.tableData.length; ii++){
      //       if(that.tableData[ii].costPrice==null||that.tableData[ii].costPrice==undefined){
      //         that.$message.error('商品成本价不能为空')
      //         return false
      //       }
      //       if(!that.tableData[ii].salePrice){
      //         that.$message.error('商品供货价不能为空')
      //         return false
      //       }
      //     }
      //  }
      params.category = {
        id: that.goodArr.categoryId,
      };
      params.globalCategory = {
        id: that.goodArr.globalCategoryId,
      };
      params.images = that.goodArr.images || [];
      params.video = that.goodArr.video;
      params.code = that.goodArr.code;
      params.name = that.goodArr.name;
      params.cate = that.goodArr.cate;
      params.deliveryType = that.goodArr.deliveryType;
      params.extend = that.goodArr.extend;
      params.place = that.goodArr.place;
      params.brand = that.goodArr.brand;
      params.purchaserId = that.goodArr.purchaserId
      params.deliveryTemplate = that.goodArr.deliveryTemplate
      params.saleStatus = that.goodArr.saleStatus
      if (this.role == "retailer") {
        params.supplierOrgId = this.supplierId;
      }
      if (that.guigeType == 1) {
        params.single = that.single;
        params.multi = [];
        params.skus = [];
      } else {
        params.multi = that.skuArrs || [];
        params.single = null;

        var currentTable = JSON.parse(JSON.stringify(that.tableData));
        // 编辑的时候，把被删除的sku也提交给后端
        const n2e = (v) => {
          return v == null || v == undefined ? "" : v;
        };
        for (var i = 0; i < that.historySkus.length; i++) {
          const row1 = that.historySkus[i];
          var deleted = true;
          for (var j = 0; j < that.tableData.length; j++) {
            const row2 = that.tableData[j];
            const k1 =
              n2e(row1.spec0) +
              n2e(row1.spec1) +
              n2e(row1.spec2) +
              n2e(row1.spec3);
            const k2 =
              n2e(row2.spec0) +
              n2e(row2.spec1) +
              n2e(row2.spec2) +
              n2e(row2.spec3);
            if (k1 == k2) {
              deleted = false;
            }
          }
          if (deleted) {
            row1.deleted = 1;
            currentTable.push(row1);
          }
        }
        params.skus = currentTable || [];
      }
      that.loading = true;
      console.log(params);
      if (that.goodArr.id) {
        params.id = that.goodArr.id;
        editGood(params).then((res) => {
          that.loading = false;
          if (res.code === 200) {
            that.$message({
              message: "商品编辑成功",
              type: "success",
            });
            // that.$router.go(-1);
            that.$router.back()
          } else {
            that.$message.error(res.message);
          }
        }).catch(err=>{
          that.loading = false;
        });
      } else {
        addGood(params).then((res) => {
          that.loading = false;
          if (res.code === 200) {
            that.$message({
              message: "新增商品成功",
              type: "success",
            });
            if (that.tuanBegin == "tuan") {
              that.$router.push({
                path:
                  "/sendtuan/detail?id=" +
                  res.data.id +
                  "&goodname=" +
                  res.data.name,
              });
            } else {
              // that.$router.go(-1);
              that.$router.back()
            }
          } else {
            that.$message.error(res.message);
          }
        }).catch(err=>{
          that.loading = false;
        });
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是JPG、JPEG、PNG、GIF格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },
    beforeVideoUpload(file) {
      console.log(file);
      const isJPG = file.type === "video/mp4";
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传视频格式只能是MP4格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },
    piliangSetPrice(n){
      var that = this
      that.bumendialogVisible = true;
      // console.log(this.tableData)
      that.piliangPrice = ''
      if(n==1){
        that.priceTitle = '批量设置划线价'
        that.priceType = 1
      }
      if(n==2){
        that.priceTitle = '批量设置' + (that.role=='supplier'?'供货价':'销售价')
        that.priceType = 2
      }
      if(n==3){
        this.priceTitle = '批量设置' + (that.role=='supplier'?'成本价':'采购价')
        that.priceType = 3
      }
    },
    querenSetPrice(n){
      var that = this
      that.tableData.map((item)=>{
        if(that.priceType==1){
          item.linePrice = parseFloat(that.piliangPrice).toFixed(2);
        }
        if(that.priceType==2){
          item.salePrice = parseFloat(that.piliangPrice).toFixed(2);
        }
        if(that.priceType==3){
          item.costPrice = parseFloat(that.piliangPrice).toFixed(2);
        }
      })
      that.bumendialogVisible = false
    },
    piliangClose(){
      this.bumendialogVisible = false;
    }
  },
  mounted() {
    this.getCategoryAll();
    this.getCategoryList();
    this.supplierFilter(null);
    var ids = this.$route.params.id;
    var tuans = this.$route.query.gotourl;
    console.log(ids);
    if (ids) {
      this.editing = true;
      this.getGoodDetails(ids);
    }
    if (tuans) {
      this.tuanBegin = tuans;
    }
  },
};
</script>

<style scoped>
.el-button--primary {
  color: #fff;
  background-color: #07c160;
  border-color: #07c160;
}
.formTable {
  width: 85%;
  display: block;
  padding: 50px;
}
.guigeBox {
  padding: 10px 0;
}
.guigeBox:hover {
  background-color: #f7f7f7;
}
.classInfo {
  font-size: 12px;
  padding-left: 20px;
  color: #999;
}
.groupMa {
  display: block;
  overflow: hidden;
  width: 100%;
}
.groupMa ol {
  float: left;
  margin: 0;
  padding: 0;
  width: 120px;
  text-align: left;
  font-size: 12px;
  margin-right: 10px;
  border: 1px solid #eee;
  padding: 2px;
  position: relative;
}
.delfun {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
  background-color: #07c160;
}
.groupMa ol span {
  display: block;
  text-align: center;
  margin-top: -10px;
  font-weight: 500;
  color: #666;
}
.subbox {
  /* padding: 20px; */
  border: 1px solid #eee;
  margin-bottom: 10px;
}
.uploadAdd {
  width: 100%;
  height: 60px;
  font-size: 18px;
}
.rightBtn {
  padding: 25px 50px 25px;
  float: right;
}
.duoguige {
  background-color: #f3f3f3;
  line-height: 24px;
  font-size: 15px;
  padding: 20px;
}
.duoguige span {
  float: right;
}
.setGuige {
  padding: 20px;
}
.guiboxzi {
  display: inline-block;
  position: relative;
  width: 560px;
  margin-right: 20px;
}
.guigeLi {
  display: block;
  line-height: 44px;
  font-size: 14px;
  padding-left: 120px;
  overflow: hidden;
}
.guigeLi span {
  display: inline-block;
  width: 120px;
  text-align: right;
  margin-left: -120px;
}
.guigeLi ol,
.addkuang {
  float: left;
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}
.guigeLi .guigeDel {
  position: absolute;
  right: 6px;
  top: 16px;
  width: 16px;
  height: 16px;
  background-color: #bbb;
  color: #fff;
  border-radius: 16px;
  text-align: center;
  line-height: 14px;
  cursor: pointer;
}
.guigeFen {
  padding-left: 120px;
  padding-top: 10px;
}
.guiTable {
  padding: 20px;
}
.sizeimgss {
  width: 60px;
  height: 60px;
  display: block;
}
.avatarVideo {
  width: 300px;
  height: 200px;
  display: block;
}
.w85 {
  width: 85%;
}
.timebox {
  width: 300px;
  display: block;
}
.additem {
  width: 32px;
  height: 32px;
  display: block;
  float: right;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #07c160;
  margin-left: 10px;
  cursor: pointer;
}
.delitem {
  width: 32px;
  height: 32px;
  display: block;
  float: right;
  color: #999;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #f3f3f3;
  margin-left: 10px;
  cursor: pointer;
}
.extendKey{
  background-color: #efefef;
  padding:5px 15px;
  border-radius: 3px;
  font-weight: bold;
}

.prefix {
  background-color: #fefefe;
  padding: 2px 5px 2px 0px;
}
#goodsForm >>> .el-form-item__label {
  font-size: 12px;
}
.property {
  width: 70%;
}
.el-upload__tip {
  width: 200px;
}
.piliangBtn{
  display: block;
  padding: 20px 0;
}

</style>